import { createStore } from "vuex";

export default createStore({
  state: {
    activities: [],
  },
  mutations: {
    setActivities(state, newActivities) {
      state.activities = newActivities;
    },
  },
  actions: {},
  getters: {},
});
