import { checkRole } from "@/services/users";
import { ref } from "vue";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("../views/login/LoginView.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/home",
      component: () => import("../views/home/HomeView.vue"),
      name: "home",
      meta: {
        requiresAuth: true,
        auth: ["teacher", "admin", "student"],
      },
    },
    {
      path: "/profile",
      component: () => import("../views/profile/ProfileView.vue"),
      meta: {
        requiresAuth: true,
        auth: ["teacher"],
      },
    },
    {
      path: "/usuarios",
      component: () => import("../views/usuarios/UsuariosView.vue"),
      meta: {
        requiresAuth: true,
        auth: ["admin"],
      },
    },
    {
      path: "/matricula",
      component: () => import("../views/matricula/MatriculaView.vue"),
      name: "matricula",
      meta: {
        requiresAuth: true,
        auth: ["teacher", "admin"],
      },
    },
    {
      path: "/matricula/:idCurso",
      component: () =>
        import("../views/matricula/detalles-curso/DetallesCurso.vue"),
      name: "detallesCurso",
      meta: {
        requiresAuth: true,
        auth: ["teacher", "admin"],
      },
    },
    {
      path: "/matricula/:idCurso/editar",
      component: () =>
        import("../views/matricula/editar-curso/EditarCurso.vue"),
      name: "editarCurso",
      meta: {
        requiresAuth: true,
        auth: ["teacher", "admin"],
      },
    },
    {
      path: "/cursos",
      component: () => import("../views/cursos/CursosView.vue"),
      name: "cursos",
      meta: {
        requiresAuth: true,
        auth: ["student", "teacher"],
      },
    },
    {
      path: "/cursos/:idCurso/:nombreCurso/:sectionId?",
      component: () => import("../views/cursos/curso/CursoView.vue"),
      name: "curso",
      meta: {
        requiresAuth: true,
        auth: ["student", "teacher"],
      },
    },
    {
      path: "/cursos/:idCurso/:nombreCurso/editar",
      component: () =>
        import(
          "../views/cursos/editar-contenido-curso/EditarContenidoCursoView.vue"
        ),
      name: "editarContenidoCurso",
      meta: {
        requiresAuth: true,
        auth: ["teacher"],
      },
    },
    {
      path: "/cursos/:idCurso/:nombreCurso/modulos/:idModulo/:nombreModulo",
      component: () => import("../views/modulos/modulo/ModuloView.vue"),
      name: "moduloView",
      meta: {
        requiresAuth: true,
        auth: ["teacher", "student"],
      },
    },
    {
      path: "/cursos/:idCurso/:nombreCurso/modulos/:idModulo/:nombreModulo/editar",
      component: () =>
        import(
          "../views/modulos/editar-contenido-modulo/EditarContenidoModuloView.vue"
        ),
      name: "editarContenidoModulo",
      meta: {
        requiresAuth: true,
        auth: ["teacher"],
      },
    },
    {
      path: "/cursos/:idCurso/:nombreCurso/modulos/:idModulo/:nombreModulo/clases/:idClase",
      component: () => import("../views/clases/ClaseView.vue"),
      name: "claseView",
      meta: {
        requiresAuth: true,
        auth: ["student", "teacher"],
      },
    },
    {
      path: "/cursos/:idCurso/:nombreCurso/modulos/:idModulo/:nombreModulo/clases/:idClase/editar",
      component: () =>
        import(
          "../views/clases/editar-contenido-clase/EditarContenidoClaseView.vue"
        ),
      name: "editarContenidoClase",
      meta: {
        requiresAuth: true,
        auth: ["teacher"],
      },
    },
    {
      path: "/cursos/:idCurso/:nombreCurso/modulos/:idModulo/:nombreModulo/clases/:idClase/quiz/:idQuiz",
      component: () => import("../views/quizzes/QuizView.vue"),
      name: "quizView",
      meta: {
        requiresAuth: true,
        auth: ["student", "teacher"],
      },
    },
    {
      path: "/quiz/:idQuiz/take",
      component: () => import("../views/quizzes/TakeQuizView.vue"),
      name: "takeQuizView",
      meta: {
        requiresAuth: true,
        auth: ["student"],
      },
    },
    {
      path: "/quiz/:idQuiz/edit",
      component: () => import("../views/quizzes/EditQuizView.vue"),
      name: "editQuizView",
      meta: {
        requiresAuth: true,
        auth: ["teacher"],
      },
    },
    {
      path: "/quiz/:idQuiz/review",
      component: () => import("../views/quizzes/ReviewQuizView.vue"),
      name: "reviewQuizView",
      meta: {
        requiresAuth: true,
        auth: ["student", "teacher"],
      },
    },
    {
      path: "/quiz/:idQuiz/grades",
      component: () => import("../views/grades/GradesView.vue"),
      name: "gradesView",
      meta: {
        requiresAuth: true,
        auth: ["teacher"],
      },
    },
    {
      name: "NotFound",
      path: "/:pathMatch(.*)*",
      component: () => import("../views/404/PageNotFound.vue"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
});

export const previousRoute = ref(null);

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = !!localStorage.getItem("user_id");
  previousRoute.value = from;

  // If the user is not logged in and is on the login page, just allow navigation
  if (!isLoggedIn && to.fullPath === "/") {
    return next();
  }

  // If the user is not logged in and trying to access any other page, redirect to login
  if (!isLoggedIn && to.fullPath !== "/") {
    return next("/");
  }

  // If the user is logged in, proceed with role checking
  let role;
  if (isLoggedIn) {
    try {
      const response = await checkRole();
      role = response.data;
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.setItem("user_id", "");
        return next("/"); // If role check fails, redirect to login
      }
    }
  }

  const requiresAuth = to.matched[0].meta.requiresAuth;
  const isAllowed = to.matched[0].meta.auth?.includes(role);

  // Redirect to home page if the user is logged in and tries to access the login page
  if (isLoggedIn && to.fullPath === "/") {
    return next("/home");
  }

  // Handle protected routes
  if (requiresAuth) {
    if (isAllowed) {
      next();
    } else {
      next("/home");
    }
  } else {
    next();
  }
});

export default router;
