import axios from "axios";

const usersRoute = "/users";

export const editMe = (formData) =>
  axios.put(`${usersRoute}/edit_me`, formData, { withCredentials: true });

export const editUser = (formData) =>
  axios.put(`${usersRoute}/edit_user`, formData, { withCredentials: true });

export const getUsers = () =>
  axios.get(`${usersRoute}`, { withCredentials: true });

export const getUserById = (user_id) =>
  axios.get(`${usersRoute}/${user_id}`, { withCredentials: true });

export const checkRole = () =>
  axios.get(`${usersRoute}/check-role`, {
    withCredentials: true,
  });

export const createUser = (formData) =>
  axios.post(`${usersRoute}/create-user`, formData, {
    withCredentials: true,
  });

export const getMyCourses = () =>
  axios.get(`${usersRoute}/get-my-courses`, {
    withCredentials: true,
  });

export const bulkCreateUsers = (formData) =>
  axios.post(`${usersRoute}/bulk-create`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    withCredentials: true,
  });

export const getUsersByRole = (role) =>
  axios.get(`${usersRoute}?role_name=${role}`, {
    withCredentials: true,
  });

export const deleteUser = (email) =>
  axios.delete(`${usersRoute}/${email}`, { withCredentials: true });

export const getUserCourseModules = (user_id, course_id) =>
  axios.get(
    `${usersRoute}/${user_id}/get-course-modules?course_id=${course_id}`,
    {
      withCredentials: true,
    }
  );
