<aside :class="{ 'is-expanded': isExpandedLocal }">
  <div class="menu-toggle-wrap">
    <img
      v-if="isExpandedLocal"
      src="@/assets/images/endless-logo.png"
      alt="AprenderCreando"
    />
    <button class="menu-toggle" @click="$emit('toggleExpanded')">
      <img class="logo-menu" src="@/assets/images/DoubleArrow.png" />
    </button>
  </div>

  <div class="logo-container">
    <img class="logo" src="@/assets/images/AC.png" alt="AprenderCreando" />
  </div>

  <div class="user-data">
    <span><b>{{ nombreCompleto }}</b></span>
    <span>{{ correo }}</span>
    <span>{{ rol }}</span>
  </div>

  <!--
        <div class="menu-icon">
            <button v-if="isExpanded" class="menu-icon" @click="goAnuncios">MIS ANUNCIOS</button>
            <button v-if="!isExpanded" class="menu-icon" @click="goAnuncios">
                <img src="@/assets/images/sideBar/anuncios-logo.png">
            </button>
        </div>
        -->

  <!--
        <div class="menu-icon">
            <button v-if="isExpanded" class="menu-icon" @click="goMensajes">MIS MENSAJES</button>
            <button v-if="!isExpanded" class="menu-icon" @click="goMensajes">
                <img src="@/assets/images/sideBar/mensajes-logo.png">
            </button>
        </div>
    -->

  <template v-if="rol">
    <div class="menu-icon">
      <button v-if="isExpanded" class="menu-icon" @click="goHome">
        INICIO
      </button>
      <button v-if="!isExpanded" class="menu-icon" @click="goHome">
        <img src="@/assets/images/sideBar/home-logo.png" />
      </button>
    </div>

    <div class="menu-icon" v-if="rol === 'admin'">
      <button v-if="isExpanded" class="menu-icon" @click="goUsuarios">
        USUARIOS
      </button>
      <button v-if="!isExpanded" class="menu-icon" @click="goUsuarios">
        <img src="@/assets/images/sideBar/usuarios-logo.png" />
      </button>
    </div>

    <div class="menu-icon" v-if="rol === 'teacher'">
      <button v-if="isExpanded" class="menu-icon" @click="goMyProfile">
        MI PERFIL
      </button>
      <button v-if="!isExpanded" class="menu-icon" @click="goMyProfile">
        <img src="@/assets/images/sideBar/usuarios-logo.png" />
      </button>
    </div>

    <div class="menu-icon" v-if="rol != 'admin'">
      <button v-if="isExpanded" class="menu-icon" @click="goCursos">
        MIS CURSOS
      </button>
      <button v-if="!isExpanded" class="menu-icon" @click="goCursos">
        <img src="@/assets/images/sideBar/cursos-logo.png" />
      </button>
    </div>

    <div class="menu-icon" v-if="rol != 'student'">
      <button v-if="isExpanded" class="menu-icon" @click="goMatricula">
        MATRICULA
      </button>
      <button v-if="!isExpanded" class="menu-icon" @click="goMatricula">
        <img src="@/assets/images/sideBar/matricula-logo.png" />
      </button>
    </div>
  </template>
</aside>
