<template src="./SideBar.html"></template>
<style lang="scss" scoped src="./SideBar.scss"></style>
<script>
export default {
  name: "SideBar",
  props: {
    isExpanded: Boolean,
  },
  computed: {
    isExpandedLocal: {
      get() {
        return this.isExpanded;
      },
      set(value) {
        this.$emit("toggleExpanded", value);
      },
    },
  },
  data() {
    return {
      rol: "",
      nombreCompleto: String,
      correo: String,
    };
  },
  async created() {
    this.getUserInfo();
    this.checkRole();
  },
  methods: {
    getUserInfo() {
      this.nombreCompleto = localStorage
        ? localStorage.getItem("first_name") +
          " " +
          localStorage.getItem("last_name")
        : "";
      this.correo = localStorage?.getItem("email") || "";
    },
    checkRole() {
      this.rol = localStorage.getItem("role_name");
    },
    goHome() {
      this.$router.push("/home");
    },
    goUsuarios() {
      this.$router.push("/usuarios");
    },
    goCursos() {
      this.$router.push("/cursos");
    },
    goMatricula() {
      this.$router.push("/matricula");
    },
    goMyProfile() {
      this.$router.push("/profile");
    },
  },
};
</script>
